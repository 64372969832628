@import "settings";

header {
  @if $topbar-full-hide {
    transition: background-color $topbar-transition-speed ease;
    * {
      opacity: 1;
      transition: opacity $topbar-transition-speed ease;
    }

    &.is-at-top {
      animation: $topbar-transition-speed ease forwards scaleup;
    }

    &.is-stuck {
      animation: $topbar-transition-speed ease forwards scaledown;
      background-color: $topbar-bottom-background-color;

      .region-left-top-bar,
      .region-right-top-bar {
        opacity: 0;
      }
    }

    @keyframes scaledown {
      from {
        height: calc(var(--header-current-height) + #{$topbar-padding * 2});
      }
      to {
        height: 0px;
      }
    }

    @keyframes scaleup {
      from {
        height: 0px;
      }
      to {
        height: calc(var(--header-current-height) + #{$topbar-padding * 2});
      }
    }
  }
  &.no-padding {
    padding: 0;
  }
}

.hiag-main-wrapper {
  @include auto-regional-colors($main-content-background);
}

@if $region-content-has-fixed-height {
  .hiag-main-content-container {
    min-height: $region-content-height;
  }
}

@include breakpoint(large) {
  #hiag-layout-content.hiag-layout-content--center {
    animation-name: centerMain;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }

  @keyframes centerMain {
    0% {
      transform: translateX(0%);
      opacity: 0;
    }
    100% {
      transform: translateX(-17.5%);
      opacity: 1;
    }
  }
}

.hiag-main-wrapper section.region:not(.region-footer) {
  --js-max-screen-width: #{$max-screen-width};
}
