@import "settings";

#block-headernavigationblock {
  @include add-fake-shadow(
    bottom,
    $global-fake-shadow-height,
    $global-fake-shadow-color,
    $global-fake-shadow-transparency
  );
}

#block-footerinhalte {
  @include tabs-underlined-style(120%, 3px solid $black, false);
}

#verwaltung-externe-amtsstellen {
  @include breakpoint(medium up) {
    .hiag-tabs-wrapper {
      display: flex;
    }
    .tabs {
      width: #{(100 / 12) * 3}#{"%"};
      @include tabs-container-vertical;
    }
    .tabs-content {
      width: #{(100 / 12) * 9}#{"%"};
      @include tabs-content-vertical;
    }
  }
}

.views-field-field-teachers .field-content {
  @include xy-grid(horizontal, true);

  & > .field-item {
    @include xy-cell(full);
    @include xy-cell-gutters($global-margin);

    @include breakpoint(medium up) {
      @include xy-cell-size(6, $global-margin);
    }
    @include breakpoint(xlarge up) {
      @include xy-cell-size(4, $global-margin);
    }
  }
}

.kindergarten-header {
  margin-bottom: $global-margin;
  @include xy-grid(horizontal, true);

  > * {
    @include xy-cell-size(full, 0);
    @include breakpoint(medium up) {
      @include xy-cell-size(50%, 0);
    }
  }
}

.kindergarten-header-content {
  background: $light-gray;
  padding: $global-padding;
}

footer,
.page-sub-content {
  --current-std-border-color: #{change-color($black, $alpha: 0.237)};
}

@media screen and (min-width: 1024px) {
  .hiag-layout-container:not(.is-front-page) {
    .node--type-chart.node--view-mode-full,
    .node--type-javascript-ui.node--view-mode-full,
    #block-hiag-base-theme-content > .node--view-mode-full > .node__content,
    #block-hiag-base-theme-content > .views-element-container {
      & > .cell:first-child,
      & > .view,
      &.hiag-tabs-wrapper,
      & > .node__content {
        position: relative;

        & > .field,
        & > *[class*="view"],
        & > .pager {
          position: relative;
          z-index: 10;
          margin: 0 28px;
        }

        &:after {
          content: "";
          width: calc(100% + 120px);
          max-width: #{($global-width / 12) * 9};
          height: calc(100% + 230px);
          background-color: $white;
          border-radius: 28px;
          box-shadow: $global-shadow-small;
          position: absolute;
          top: -180px;
          left: 0;
        }
      }
      & > .view:after {
        top: -120px;
        height: calc(100% + 165px);
      }

      & > .field--name-field-tab-item > *,
      .fc-header-toolbar {
        position: relative;
        z-index: 1;

        &.hiag-tabs,
        &.fc-toolbar {
          margin-left: 1.05rem;
        }
      }
    }

    #block-hiag-base-theme-page-title {
      position: relative;
      z-index: 11;

      .page-title {
        margin: 0 28px;
      }
    }
  }
}

#block-orbitstartseite.card {
  border-radius: 0px;
}

button:hover,
.button:hover {
  color: var(--white);
}
