@import "settings";

.field__label {
  @include hiag-label($field-label-color, $field-label-alpha);
}

.field--label-inline {
  display: flex;

  .field__label,
  & > .field__item,
  .field__items {
    padding-right: 0.5em;
  }

  .field__label::after {
    content: ":";
  }
}

.text-formatted {
  .field--label-inline {
    .field__label {
      flex: 1;
    }

    .field__items,
    & > .field__item {
      flex: $field-label-inline-flex;
    }

    @include breakpoint($field-label-inline-breakpoint down) {
      flex-direction: column;
    }
  }
}

.card {
  .field__label {
    color: change-color(
      color-pick-contrast(
        $card-background,
        $field-label-color $field-label-color-dark
      ),
      $alpha: $field-label-alpha
    );
  }
  &.node--type-person {
    .field__label {
      color: change-color(
        color-pick-contrast(
          $hiag-person-background-color,
          $field-label-color $field-label-color-dark
        ),
        $alpha: $field-label-alpha
      );
    }
  }
}

footer {
  .card {
    .field__label {
      color: change-color(
        color-pick-contrast(
          $footer-background-color,
          $field-label-color $field-label-color-dark
        ),
        $alpha: $field-label-alpha
      );
    }
  }
}

[dir="rtl"] .field--label-inline .field__label,
[dir="rtl"] .field--label-inline .field__items {
  padding-right: 0;
  padding-left: 0.5em;
}

.node--view-mode-hiag-card {
  .field--name-field-mitteilung-vom {
    font-size: $small-font-size;
  }
}

// body
//-----

.field--name-body--unfold-wrapper {
  $button-area-size: 4 * $global-margin;
  position: relative;
  overflow: hidden;
  transition: height 2s;

  button.unfold-handler-button {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;

    display: flex;
    span:first-child {
      width: 60px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    background: linear-gradient(
      180deg,
      change-color($white, $alpha: 0) 0%,
      $white 100%
    );
    left: 0;
    bottom: 0;
  }

  .material-symbols-outlined {
    transition: transform 0.2s;
  }

  &[unfold-state="closed"] {
    height: 200px;

    &:before {
      height: 2 * $button-area-size;
    }

    button.unfold-handler-button .material-symbols-outlined {
      transform: rotate(0deg);
    }
  }

  &[unfold-state="open"] {
    height: calc(var(--unfold-container-height) + #{$button-area-size});

    &:before {
      height: $button-area-size;
    }

    button.unfold-handler-button .material-symbols-outlined {
      transform: rotate(180deg);
    }
  }
}

.field--name-body,
.views-field-body {
  a.hiag-external-link {
    display: inline-flex;
    align-items: baseline;
  }

  img.align-right {
    margin-left: $global-margin;
  }
  img.align-left {
    margin-right: $global-margin;
  }
}
